import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { gql, useMutation } from '@apollo/client';
import addToMailchimp from "gatsby-plugin-mailchimp"

const CONTACT_INFORMATION = gql`
  mutation($data: ContactInput!) {
    sendContactInformation(data: $data)
  }
`;

const ContactForm = () => {
  let messageTimer;

  const intl = useIntl();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [postMessage, setPostMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [addContactInformation] = useMutation(CONTACT_INFORMATION);

  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [subscriptionMessage, setSubscriptionMessage] = useState("");
  const [subscribe, setSubscribe] = useState(false);

  const validateForm = () => {
    let valid = true;
    if(name === "") {
      valid = false;
      setSuccess(false);
      setPostMessage("Please enter your name.");
    } else if(email === "") {
      valid = false;
      setSuccess(false);
      setPostMessage("Please enter your email.");
    } else if(message === "") {
      valid = false;
      setSuccess(false);
      setPostMessage("Please enter a message.");
    }

    return valid;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if(validateForm()) {
      try {
        const result = await addContactInformation({ variables: 
          {
            data: {
              name,
              email,
              message
            }
          } 
        });
        setName("");
        setEmail("");
        setMessage("");

        if(result && result.data.sendContactInformation) {
          setSuccess(true);
          setPostMessage("Thank you for contacting us. We will be in touch shortly.");
        } else {
          setSuccess(false);
          setPostMessage("Something seems to have gone wrong. Sorry for the inconvenience.");
        }
      } catch(err) {
        setSuccess(false);
        setPostMessage("Something seems to have gone wrong. Sorry for the inconvenience.");
      }
    }

    messageTimer = setTimeout(() => {
      setPostMessage("");
    }, 1000 * 6);
  }

  const onSubscribe = async (e) => {

    e.preventDefault();

    try {
        
      const response = await addToMailchimp(subscribeEmail);

      if(response && response.result === "success") {
        setSubscribeEmail("");
        setSubscribe(true);
        setSubscriptionMessage(response.msg);
      } else {
        setSubscribe(false);
        setSubscriptionMessage(response.msg);
      }

    } catch(err) {
      setSubscribe(false);
      setSubscriptionMessage("Something seems to have gone wrong. Sorry for the inconvenience.");
    }

    messageTimer = setTimeout(() => {
      setSubscriptionMessage("");
    }, 1000 * 6);
  }

  return (
    <>
      <h3 className="header" style={{paddingTop: "50px"}}>{intl.formatMessage({ id: "contact.header" })}</h3>
      <div style={{width: "100%"}}>
        <div className="card contact-card">
          <div className="card-wrapper">
            <form className="contact-form" onSubmit={onSubmit}>
              <div className="contact-input-wrapper">
                <label htmlFor="contact-name">{intl.formatMessage({ id: 'contact.form.name' })}</label>
                <input 
                  id="contact-name"
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)} />
              </div>
              <div className="contact-input-wrapper">
                <label htmlFor="contact-email">{intl.formatMessage({ id: 'contact.form.email' })}</label>
                <input 
                  id="contact-email"
                  type="text"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="contact-input-wrapper">
                <label htmlFor="contact-message">{intl.formatMessage({ id: 'contact.form.message' })}</label>
                <textarea
                  id="contact-message"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} />
                  <div className="form-message"></div>
              </div>
              <input type="submit" className="callout-button"
                value={intl.formatMessage({ id: 'contact.form.send' })}/>
            </form>
          </div>
          {postMessage !== "" && 
            <div className="post-message-wrapper">
              <div className={success ? "post-success-message" : "post-error-message"}>
                {postMessage}
              </div>
            </div>
          }
        </div>
      </div>
      
      <h3 className="header" style={{paddingTop: "50px"}}>{intl.formatMessage({ id: "contact.newsletter.heading" })}</h3>
      <div style={{width: "100%"}}>
        <div className="card contact-card">
          <div className="card-wrapper">
              <form className="contact-form" onSubmit={onSubscribe}>
                <div className="contact-input-wrapper">
                  <label htmlFor="contact-email">{intl.formatMessage({ id: 'contact.newsletter.email' })}</label>
                  <input 
                    id="contact-email"
                    type="text"
                    name="subscribeEmail"
                    value={subscribeEmail}
                    onChange={(e) => setSubscribeEmail(e.target.value)} />
                </div>

                <input type="submit" className="callout-button"
                  value={intl.formatMessage({ id: 'contact.newsletter.signup' })}/>
              </form>
          </div>
          {subscriptionMessage !== "" &&
            <div className="post-message-wrapper">
              <div className={subscribe ? "post-success-message" : "post-error-message"}>
                {subscriptionMessage}
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default ContactForm
